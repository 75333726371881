
import { Options, Vue } from 'vue-class-component'
import Section from '@/components/section.vue' // @ is an alias to /src
import PanelMenu from 'primevue/panelmenu'
import Breadcrumb from 'primevue/breadcrumb'
import sProduct from '@/components/sproduct.vue'
import { lDatasource } from '@/api/datasource'
@Options({
  components: { Section, sProduct, PanelMenu, Breadcrumb },
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbs: [
        { label: 'Sản phẩm', to: '/san-pham' }
      ],
      url: '',
      lQuery: {
        page: 'customer',
        lang: 'vi'
      },
      quantity: 1,
      columns: [],
      pricegroup: [],
      layout: 'list',
      ldata: {},
      lsdatas: [],
      mnitems: []
    }
  },
  created () {
    const cmslug = this.$route.params.id ? this.$route.params.id : 0
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      this.glmenus()
    },
    glmenus () {
      lDatasource({ code: 'productcat', lg: 'vi' }).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.rdata.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.ldata.title, url: '/san-pham/' + item.ldata.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    }
  }
})
export default class Home extends Vue {}
