
import { ref, reactive, defineComponent } from 'vue'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputNumber from 'primevue/inputnumber'
import PanelMenu from 'primevue/panelmenu'
import Breadcrumb from 'primevue/breadcrumb'
import Skeleton from 'primevue/skeleton'
import { gProduct } from '@/api/product'
import { lDatasource } from '@/api/datasource'
import { useI18n } from 'vue-i18n'
import sProduct from '@/components/sproduct.vue'
export default defineComponent({
  components: { Button, DataTable, Column, InputNumber, PanelMenu, Breadcrumb, sProduct, Skeleton },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/', label: 'Home' },
      breadcrumbs: [
        { label: 'Sản phẩm', to: '/san-pham' }
      ],
      url: '',
      lQuery: {
        page: 'customer',
        lang: 'vi'
      },
      slug: '',
      quantity: 10,
      loading: false,
      columns: [],
      pricegroup: [{}],
      layout: 'list',
      lg: 'vi',
      ldata: { code: '', pricetype: '', title: '', shortdesc: '' },
      mnitems: []
    }
  },
  created () {
    var glg: any = sessionStorage.getItem('st-lg')
    this.lg = glg
    const cmslug = this.$route.params.id ? this.$route.params.id : 0
    if (this.$route.query.m && this.$route.query.kmcode) {
      var email: any = this.$route.query.m
      var kmcode: any = this.$route.query.kmcode
      sessionStorage.setItem('st-email', email)
      sessionStorage.setItem('st-kmcode', kmcode)
    }
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      this.loading = true
      gProduct({ slug: cmslug, lg: this.lg }).then((res: any) => {
        if (res) {
          var tmp: any = []
          var tmpldata: any = res.data.data
          this.ldata = tmpldata
          var tmpmttitle = tmpldata.title + ' | Kpibsc.com'
          document.title = tmpmttitle
          const descEl = document.querySelector('head meta[name="description"]')
          var tmpdesc: any = tmpldata.shortdesc ? (tmpldata.title + ', ' + tmpldata.shortdesc.replace(/(<([^>]+)>)/gi, '')) : tmpldata.title
          if (descEl !== null) {
            descEl.setAttribute('content', tmpdesc)
          }
          const titleOg = document.querySelector('head meta[property="og:title"]')
          if (titleOg !== null) {
            titleOg.setAttribute('content', tmpmttitle)
          }
          const descOg = document.querySelector('head meta[property="og:description"]')
          if (descOg !== null) {
            descOg.setAttribute('content', tmpdesc)
          }
          const urlOg = document.querySelector('head meta[property="og:url"]')
          if (urlOg !== null) {
            urlOg.setAttribute('content', location.href)
          }
          if (this.ldata.pricetype === '320220316033911') {
            this.quantity = 1
          }
          this.url = res.data.url
          this.breadcrumbs = []
          if (res.data.cat) {
            this.slug = res.data.cat.ldata.slug + '-' + res.data.cat.code
            var title = res.data.cat.ldata.title
            if (this.lg === 'en' && res.data.cat.ldata.titleen) {
              title = res.data.cat.ldata.titleen
            } else if (this.lg === 'de' && res.data.cat.ldata.titlede) {
              title = res.data.cat.ldata.titlede
            }
            this.breadcrumbs.push({ label: title, to: '/' + this.slug })
          }
          if (res.data.pricegroup) {
            var tmpcol: any = [{ field: 'dv', header: '' }]
            var tmprow: any = { dv: this.t('useronmonth') }
            var formatter = new Intl.NumberFormat('vi-VN', {
              style: 'currency',
              currency: 'VND'
            })
            res.data.pricegroup.forEach((item: any) => {
              tmpcol.push({ field: item.code, header: item.title + '<br/>( ' + item.minuser + ' - ' + item.maxuser + ' )' })
              tmprow[item.code] = formatter.format(item.price)
            })
            this.columns = tmpcol
            this.pricegroup = []
            this.pricegroup.push(tmprow)
          }
        }
        this.loading = false
        this.$emit('loadfooter')
      })
      this.glmenus()
    },
    glmenus () {
      lDatasource({ code: 'productcat' }).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.rdata.forEach((item: any) => {
            var title = item.ldata.title
            if (this.lg === 'en' && item.ldata.titleen) {
              title = item.ldata.titleen
            } else if (this.lg === 'de' && item.ldata.titlede) {
              title = item.ldata.titlede
            }
            tmp.push({ key: item.id, title: title, url: '/danh-muc-san-pham/' + item.ldata.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    },
    boscart () {
      var dtvl = { stype: 0, pdcode: this.ldata.code, nuser: this.quantity }
      this.openshopcart(dtvl)
    },
    openshopcart (vl: any) {
      this.$emit('openshopcart', vl)
    }
  }
})
