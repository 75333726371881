
import { defineComponent } from 'vue'
import PanelMenu from 'primevue/panelmenu'
import { gProduct, lProduct } from '@/api/product'
import { lDatasource } from '@/api/datasource'
import sProduct from '@/components/sproduct.vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: { PanelMenu, sProduct },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbs: [
        { label: 'Sản phẩm', to: '/san-pham' }
      ],
      url: '',
      lQuery: {
        page: 'customer',
        lg: 'vi'
      },
      quantity: 1,
      columns: [],
      pricegroup: [],
      layout: 'list',
      slug: '',
      ldata: {},
      lsdatas: [],
      mnitems: []
    }
  },
  created () {
    var glg: any = sessionStorage.getItem('st-lg')
    this.lQuery.lg = glg
    const cmslug = this.$route.params.id ? this.$route.params.id : 0
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      this.slug = cmslug
      this.glmenus()
    },
    glmenus () {
      lDatasource({ code: 'productcat' }).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.rdata.forEach((item: any) => {
            var title = item.ldata.title
            if (this.lQuery.lg === 'en' && item.ldata.titleen) {
              title = item.ldata.titleen
            } else if (this.lQuery.lg === 'de' && item.ldata.titlede) {
              title = item.ldata.titlede
            }
            tmp.push({ key: item.id, title: title, url: '/danh-muc-san-pham/' + item.ldata.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
        this.$emit('loadfooter')
      })
    }
  }
})
